.menuCategoryCard {
    z-index: 1;
    height: 160px;
    margin: 10px 10px 10px 0;
    display: flex;
    flex: 1;
    flex-basis: 30%;
    min-width: 300px;
    background: var(--col--light);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    position: relative;
}

.contentfulBadge {
    left: 0;
    top: 0;
}

.badgeMenuCategoryCard {
    border-radius: 0;
}

@media (max-width: 1200px) {
    .menuCategoryCard {
        flex-basis: 40%;
    }
}

.imageSection,
.contentSection {
    width: 50%;
    display: flex;
    justify-content: center;
}

.imageSection {
    align-items: center;
    padding-left: 10px;
}

.imageSection img {
    align-self: flex-start;
}

.contentSection {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 25px;
    text-align: left;
}

.title {
    font-family: var(--ff--pharagraph-strong);
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: var(--col--dark);
    margin: 0;
}

.image {
    max-width: 90%;
}

@media (max-width: 768px) {
    .menuCategoryCard {
        width: 100%;
    }

    .title {
        font-size: 20px;
        line-height: 22px;
    }
}

@media (max-width: 425px) {
    .menuCategoryCard {
        margin: 7px 0;
        height: 119px;
    }
}
