.badge {
    composes: badge from './badge.vars.css';
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.15));
}

.inspireBadge,
.polygonalBadge {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    top: 0px;
    text-transform: uppercase;
}

.inspireBadge {
    box-sizing: var(--inspire-badge-box-sizing, content-box);
    height: var(--inspire-badge-height, 24px);
    padding: 4px 6px;
    font-family: var(--subhead-font);
    font-size: var(--inspire-badge-font-size, 14px);
    line-height: var(--inspire-badge-line-height, 14px);
}

.polygonalBadge {
    composes: t-product-title-small from global;
    box-sizing: border-box;
    padding: 8px 15px 4px 6px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 11px) 100%, 0% 100%);
}

.productBadge,
.bogoBadge {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: var(--product-badge-padding);
    font-style: normal;
    text-transform: uppercase;
}

.productBadge {
    font-family: var(--product-badge-font-family);
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
}

.bogoBadge {
    font-family: var(--ff--pharagraph);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    background-color: var(--col--primary1);
    filter: none;
}

.productBadge ~ .bogoBadge {
    top: 28px;
}

.dealBadge {
    display: flex;
    align-items: center;
    padding: 5px 6px;
    font-family: var(--ff--header);
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
}

@media screen and (max-width: 767px) {
    .polygonalBadge {
        padding: 6px 15px 3px 6px;
    }

    .contentfulBadge {
        font-size: var(--contentful-badge-mobile-font-size);
        line-height: var(--contentful-badge-mobile-line-height);
    }
}

@media (max-width: 425px) {
    .productBadge {
        padding: var(--product-badge-mobile-padding);
        font-size: 12px;
        line-height: var(--product-badge-mobile-line-height);
    }

    .productBadge ~ .bogoBadge {
        top: 22px;
    }
}

.inspireBadge::after,
.dealBadge::after {
    content: '';
    position: absolute;
    width: var(--inspire-badge-width);
    height: 100%;
    right: -9px;
    top: 0;
    clip-path: var(--badge-clip-path);
}

.productBadge::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    right: -10px;
    top: 0;
    clip-path: var(--product-badge-clip-path);
}

.bogoBadge::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 100%;
    right: -10px;
    top: 0;
    clip-path: var(--product-badge-clip-path);
    background-color: var(--col--primary1);
}

.inspireBadge.dark,
.productBadge.dark,
.dealBadge.dark,
.polygonalBadge.dark {
    background-color: var(--badge-dark-background-color);
    color: var(--badge-dark-color);
}

.inspireBadge.dark::after,
.productBadge.dark::after,
.dealBadge.dark::after,
.polygonalBadge::after {
    background-color: var(--badge-dark-background-color);
}

.inspireBadge.light,
.inspireBadge.light::after {
    background-color: var(--col--secondary3);
    color: var(--col--light);
}

.inspireBadge.deepdark,
.inspireBadge.deepdark::after {
    background-color: var(--col--dark);
    color: var(--col--light);
}

.polygonalBadge.primary,
.inspireBadge.primary,
.inspireBadge.primary::after {
    background-color: var(--col--primary1);
    color: var(--col--light);
}

.inspireBadge.secondary,
.inspireBadge.secondary::after {
    background: var(--col--secondary5);
    color: var(--col--dark);
}

.contentfulBadge {
    padding: 0 14px 0 6px;
    font-size: var(--contentful-badge-font-size);
    font-family: var(--ff--pharagraph);
    font-style: normal;
    line-height: var(--contentful-badge-line-height);
    font-weight: var(--contentful-badge-font-weight);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, calc(100% - 11px) 100%, 0% 100%);
    height: 28px;
}

@media (max-width: 767px) {
    .contentfulBadge {
        font-size: var(--contentful-badge-mobile-font-size);
        line-height: var(--contentful-badge-mobile-line-height);
        height: 21px;
    }
}
