.badge {
    --inspire-badge-width: 10px;
    --inspire-badge-box-sizing: border-box;
    --inspire-badge-height: 20px;
    --inspire-badge-font-size: 10px;
    --inspire-badge-line-height: 12px;
    --badge-dark-background-color: var(--col--dark);
    --badge-dark-color: var(--col--light);
    --product-badge-font-family: var(--subhead-font);
    --product-badge-padding: 6px 4px 6px 6px;
    --product-badge-mobile-padding: 4px 4px 4px 6px;
    --product-badge-mobile-line-height: 14px;
    --badge-clip-path: polygon(100% 0%, 45% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    --product-badge-clip-path: polygon(0 0, 8% 100%, 100% 0);
    --contentful-badge-font-size: 12px;
    --contentful-badge-line-height: 14px;
    --contentful-badge-font-weight: 800;
    --contentful-badge-mobile-font-size: 10px;
    --contentful-badge-mobile-line-height: 12px;
}
