.counter {
    font-family: var(--ff--pharagraph);
    font-size: 16px;
    line-height: 26px;
    color: var(--col--gray6);
    display: block;
    padding-bottom: 10px;
}

.link {
    font-family: var(--ff--pharagraph-strong);
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: var(--col--dark);
}

.link:hover {
    text-decoration-line: none;
}

@media (max-width: 768px) {
    .counter {
        font-size: 11px;
        line-height: 20px;
    }

    .link {
        font-size: 16px;
        line-height: 20px;
    }
}
